@import "~bootstrap/scss/bootstrap-grid";

h1.errorpage-header {
    font-size: 5rem;
    font-weight: bold;
    background: url('../../assets/background.png') no-repeat;
    background-clip: text;
    background-size: cover;
    background-position: center;
    -webkit-text-fill-color: transparent;
}

@include media-breakpoint-up(sm) {
    h1.errorpage-header {
        font-size: 8rem;
    }
}

@include media-breakpoint-up(md) {
    h1.errorpage-header {
        font-size: 10rem;
    }
}

@include media-breakpoint-up(lg) {
    h1.errorpage-header {
        font-size: 12rem;
    }
}

@include media-breakpoint-up(xl) {
    h1.errorpage-header {
        font-size: 13rem;
    }
}

h2.errorpage-subheader {
    color: #000;
    font-size: 1.5rem;
    font-weight: bold;
    text-transform: uppercase;
}

a.errorpage-btn, button.errorpage-btn {
    padding: 0.7rem 1.5rem;
    font-size: 0.9rem;
    text-transform: uppercase;
    font-weight: bold;
    border-radius: 10rem;
}