
.modal button.close {
    color: #f8f9fa;
}

.modal .modal-header {
    border-top-left-radius: 0.2rem; 
    border-top-right-radius: 0.2rem;
}

.modal .modal-title {
    line-height: 1;
    padding-left: 2rem;
}
