@import "~bootstrap/scss/bootstrap-grid";

.table th.fit, .table td.fit {
    white-space: nowrap;
    width: 1%;
}

@include media-breakpoint-up(lg) {
    .table th.nowrap-lg, .table td.nowrap-lg {
        white-space: nowrap;
    }
}

.table tbody td {
    vertical-align: middle;
}

button.btn-table {
    min-width: 34px;
}

tr.tr-total th, tr.tr-total td {
    padding-top: 0;
    padding-bottom: 0;
}


/* Edit row input sizes */

td.date {
    max-width: 5rem;
}

@include media-breakpoint-up(sm) {
    td.date {
        max-width: 8rem;
    }
}

@include media-breakpoint-up(md) {
    td.date {
        max-width: 9rem;
    }
}

td.project {
    max-width: 17rem;
}

td.profile {
    max-width: 10rem;
}

td.hours {
    max-width: 6rem;
}

@include media-breakpoint-up(sm) {
    td.hours {
        min-width: 4rem;
    }
}