@import "~bootstrap/scss/bootstrap-grid";

@include media-breakpoint-up(lg) {
    div.fg-date {
        max-width: 13rem;
    }
    div.fg-project {
        max-width: 22rem;
    }
    div.fg-profile {
        max-width: 15rem;
    }
    div.fg-allocationType {
        max-width: 13rem;
    }
    div.fg-hours {
        max-width: 6rem;
    }
}