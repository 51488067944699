
/* Card header */

.card-header.table-header {
    padding-top: .53rem;
    padding-bottom: .53rem;
}

div.month {
    width: 7.5rem;
    text-align: center;
}

button.btn-next-month:disabled {
    opacity: 0.3;
}