
.card-header.calendar-header {
    padding-top: .53rem;
    padding-bottom: .53rem;
}

.caption-element {
    white-space: nowrap;
    width: 11rem;
}

.justify-content-evenly {
    justify-content: space-evenly !important;
}