
td.td-day {
    user-select: none;
    cursor: pointer;
}

td.td-day:hover, td.td-day.selected {
    background-color: #c3e6cb;
}

td.td-day.bg-success:hover, td.td-day.bg-success.selected {
    box-shadow: inset 0 0 10px #1e7e34, inset 0 0 10px #1e7e34, inset 0 0 10px #1e7e34;
}

td.td-day.bg-info:hover, td.td-day.bg-info.selected {
    box-shadow: inset 0 0 10px #117a8b, inset 0 0 10px #117a8b, inset 0 0 10px #117a8b;
}

/* TODAY */

td.td-day.today {
    font-weight: bold;
}

td.td-day.today span::after {
    content: '';
    position: absolute;
    left: 50%;
    margin-left: -2px;
    bottom: 0;
    margin-bottom: -3px;
    width: 4px;
    height: 4px;
    border-radius: 50%;
}

td.td-day.today span::after {
    background-color: black;
}

td.td-day.today.bg-success span::after, td.td-day.today.bg-danger span::after, td.td-day.today.bg-info span::after {
    background-color: white;
}
