@import "~bootstrap/scss/bootstrap-grid";

nav.navbar-csb {
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
}

@include media-breakpoint-down(xs) {
    .btn.btn-block-xs {
        display: block;
        width: 100%;
    }
}

@include media-breakpoint-down(sm) {
    .btn.btn-block-sm {
        display: block;
        width: 100%;
    }
}

@include media-breakpoint-down(md) {
    .btn.btn-block-md {
        display: block;
        width: 100%;
    }
}